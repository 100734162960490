import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userReducer";
import campaignReducer from "./campaign/campaignReducer";

const reduxStore = configureStore({
	reducer: {
		userReducer,
		campaignReducer,
	},
});

export default reduxStore;
