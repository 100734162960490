import React from "react";
import { Modal, ConfigProvider } from "antd";
import success from "../../images/success_icon.png";

const CopyToClipboard = ({ isClick, setIsClick }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgBase: "rgba(17.71, 16.29, 16.29, 0.85)",
          borderRadius: "10px",
        },
      }}
    >
      <Modal open={isClick} footer={null} closable={false} centered width={230}>
        <div className="text-center">
          <img src={success} alt="" style={{ width: "60px", height: "60px" }} />
          <p
            className="text-center mt-1 font-16 font-weight-600 t-white"
            style={{ lineHeight: "15px" }}
          >
            Copy To Clipboard
          </p>
        </div>
      </Modal>
    </ConfigProvider>
  );
};
export default CopyToClipboard;