import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
	const navigate = useNavigate();

	const { userProfile, isLoading: userRLoading } = useSelector((state) => state.userReducer);

	useEffect(() => {
		if (userProfile) {
			navigate(`/home${window.location.search}`);
		}

		if (!userProfile && !userRLoading) {
			navigate(`/login${window.location.search}`);
		}
	}, [navigate, userProfile, userRLoading]);

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			Loading...
		</div>
	);
};

export default LandingPage;
