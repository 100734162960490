import axiosClient from "../../utils/axiosClient";
import { SET_USER_PROFILE, SET_USER_ERROR, SET_USER_LOADING } from "./userActionTypes";

export const fetchUserProfileIfNotExist = () => async (dispatch, getState) => {
	try {
		const { userProfile } = getState()?.userReducer;
		if (!userProfile) {
			dispatch({ type: SET_USER_ERROR, payload: null });
			dispatch({ type: SET_USER_LOADING, payload: true });

			const res = await axiosClient.get("/users/details");
			dispatch({ type: SET_USER_PROFILE, payload: res.data.data });
		}
	} catch (error) {
		const errorMessage = error?.response?.data?.message || "Unable fetch profile.";

		dispatch({ type: SET_USER_ERROR, payload: errorMessage });
	}
};

export const userLogout = () => async (dispatch, getState) => {
	try {
		dispatch({ type: SET_USER_ERROR, payload: null });
		dispatch({ type: SET_USER_PROFILE, payload: null });
		dispatch({ type: SET_USER_LOADING, payload: false });
		localStorage.removeItem("user-token");
	} catch (error) {
		const errorMessage = error?.response?.data?.message || "Unable fetch profile.";

		dispatch({ type: SET_USER_ERROR, payload: errorMessage });
	}
};
