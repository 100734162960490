import { Modal } from "antd";

const StoreChannelValidateAndRequiredModal = ({ open, onCancel }) => {
	return (
		<Modal centered footer={null} open={open} closable={false}>
			<div
				style={{
					textAlign: "center",
					fontSize: "18px",
					fontWeight: "bold",
					color: "#000",
					marginBottom: "10px",
				}}
			>
				Store Channel is Invalid, <br />
				Please scan the QR code again.
			</div>
		</Modal>
	);
};

export default StoreChannelValidateAndRequiredModal;
