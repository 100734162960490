export default function InfoIcon() { 
	return (
			<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
				<g clipPath="url(#clip0_301_10218)">
					<path
						d="M9.5 1C7.81886 1 6.17547 1.49852 4.77766 2.43251C3.37984 3.3665 2.29037 4.69402 1.64703 6.24719C1.00368 7.80036 0.835355 9.50943 1.16333 11.1583C1.4913 12.8071 2.30085 14.3217 3.4896 15.5104C4.67834 16.6992 6.1929 17.5087 7.84173 17.8367C9.49057 18.1646 11.1996 17.9963 12.7528 17.353C14.306 16.7096 15.6335 15.6202 16.5675 14.2223C17.5015 12.8245 18 11.1811 18 9.5C18 7.24566 17.1045 5.08365 15.5104 3.48959C13.9164 1.89553 11.7543 1 9.5 1ZM9.5 16.9375C8.029 16.9375 6.59104 16.5013 5.36795 15.6841C4.14486 14.8668 3.19158 13.7052 2.62865 12.3462C2.06572 10.9872 1.91844 9.49175 2.20541 8.04901C2.49239 6.60628 3.20074 5.28105 4.2409 4.24089C5.28105 3.20074 6.60629 2.49239 8.04902 2.20541C9.49175 1.91843 10.9872 2.06572 12.3462 2.62865C13.7052 3.19157 14.8668 4.14486 15.6841 5.36795C16.5013 6.59104 16.9375 8.029 16.9375 9.5C16.9375 11.4725 16.1539 13.3643 14.7591 14.7591C13.3643 16.1539 11.4725 16.9375 9.5 16.9375Z"
						fill="white"
					/>
					<path
						d="M9.66884 4.20335C9.03671 4.1929 8.41004 4.32181 7.83336 4.58091C7.25668 4.84001 6.74414 5.22295 6.33217 5.70251C6.27494 5.75555 6.22893 5.81954 6.19687 5.89068C6.1648 5.96182 6.14734 6.03867 6.14551 6.11668C6.14547 6.18865 6.15991 6.2599 6.18797 6.32618C6.21602 6.39246 6.25713 6.45241 6.30883 6.50248C6.36053 6.55255 6.42178 6.59171 6.48892 6.61763C6.55607 6.64355 6.62774 6.65569 6.69967 6.65335C6.85378 6.64953 7.00113 6.58934 7.11384 6.48418C7.42561 6.11883 7.81283 5.82535 8.24886 5.62396C8.68489 5.42256 9.15938 5.31801 9.63967 5.31751C11.0163 5.31751 11.8738 6.20418 11.8738 7.30668V7.33585C11.8738 8.62501 10.8472 9.34251 9.22551 9.46501C9.15984 9.46755 9.09545 9.48392 9.03656 9.51306C8.97766 9.54221 8.92558 9.58346 8.88373 9.63412C8.84187 9.68478 8.8112 9.74371 8.79369 9.80705C8.77619 9.87039 8.77227 9.93672 8.78217 10.0017C8.78217 10.0017 8.78217 11.355 8.78217 11.6058C8.79668 11.7502 8.86449 11.884 8.97236 11.9811C9.08022 12.0782 9.22039 12.1316 9.36551 12.1308H9.42967C9.57406 12.1163 9.70786 12.0485 9.80494 11.9407C9.90201 11.8328 9.9554 11.6926 9.95468 11.5475V10.3458C11.7047 10.1008 13.1222 9.17918 13.1222 7.26585V7.23668C13.1047 5.48668 11.7397 4.20335 9.66884 4.20335Z"
						fill="white"
					/>
					<path d="M9.37174 15.0125C9.77445 15.0125 10.1009 14.686 10.1009 14.2833C10.1009 13.8806 9.77445 13.5542 9.37174 13.5542C8.96904 13.5542 8.64258 13.8806 8.64258 14.2833C8.64258 14.686 8.96904 15.0125 9.37174 15.0125Z" fill="white" />
				</g>
				<defs>
					<clipPath id="clip0_301_10218">
						<rect width="21" height="21" fill="white" transform="translate(-1 -1)" />
					</clipPath>
				</defs>
			</svg>
	);
}