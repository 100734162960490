import React, { useRef, useState, useEffect } from "react";
import { Button, Input, message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserEligibleCNYCampaignByChannelIfNotExist } from "../redux/campaign/campaignActions";
import upload_img from "../images/upload-img-icon.png";
import primaryBgImg from "../images/testing.png";
import textLogo from "../images/textLogo.png";
import UserChannelValidationModal from "../components/UserChannelValidationModal/UserChannelValidationModal";
import axiosClient from "../utils/axiosClient";
import ReceiptUploadSuccessModal from "../components/ReceiptUploadSuccessModal/ReceiptUploadSuccessModal";
import { fetchUserProfileIfNotExist } from "../redux/user/userActions";
import { HALEON_CNY_CAMPAIGNS } from "../consts/haleonCnyCampaignsChannels";
const SG_CAMPAIGN_ID = HALEON_CNY_CAMPAIGNS.SG;
const CAPITAVOUCHER_REWARD_OPTIONS = [
  {
    minReciptAmount: 38,
    maxReciptAmount: 67,
    rewardId: process.env.REACT_APP_CNY_CAMPAIGN_CAPITAVOUCHER_MIN_38_REWARD_ID,
  },
  {
    minReciptAmount: 68,
    rewardId: process.env.REACT_APP_CNY_CAMPAIGN_CAPITAVOUCHER_MIN_68_REWARD_ID,
  },
];

const Upload = () => {
  const navigate = useNavigate();
  const uploadref = useRef();
  const [imageFile, setImageFile] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const dispatch = useDispatch();

  const { userProfile, error: userRError } = useSelector(
    (state) => state.userReducer
  );
  const { campaign, error: camRError } = useSelector(
    (state) => state.campaignReducer
  );

  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [receiptAmount, setReceiptAmount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userSelectedRewardId, setUserSelectedRewardId] = useState();
  const userChannel = userProfile?.personalInfo?.registerSource?.channel;
  const [prevCamEntry, setPrevCamEntry] = useState();
  const [searchParams] = useSearchParams();
  const prevCamEntryId = searchParams.get("prevCamEntryId");
  const isResubmit = prevCamEntry && prevCamEntryId;

  useEffect(() => {
    if (userRError) {
      message.error(userRError || "An error occurred while fetching data.");
    }
  }, [userRError]);

  useEffect(() => {
    if (camRError) {
      message.error(camRError || "An error occurred while fetching data.");
    }
  }, [camRError]);

  useEffect(() => {
    dispatch(fetchUserProfileIfNotExist());
  }, [dispatch]);

  useEffect(() => {
    if (!userChannel) return;
    dispatch(fetchUserEligibleCNYCampaignByChannelIfNotExist(userChannel));
  }, [dispatch, userChannel]);

  const handleDisplayImage = (e) => {
    let render = new FileReader();
    setErrorMessage({ ...errorMessage, submit: null });
    const supportedImgFormat = ["image/jpeg", "image/jpg", "image/png"];

    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];

      if (supportedImgFormat.includes(selectedFile.type)) {
        setImageFile(selectedFile);
        render.readAsDataURL(selectedFile);
        render.onload = (res) => {
          setImageUrl(res.target.result);
        };
      } else {
        setErrorMessage("Please upload a JPEG, JPG, or PNG image only!");
        setTimeout(() => {
          setErrorMessage("");
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (!prevCamEntryId || prevCamEntryId?.length !== 36) return;
    if (!prevCamEntry) {
      setIsLoading(true);
      // uuid have 26 length
      axiosClient
        .get(`/campaign-entries/${prevCamEntryId}`)
        .then((res) => {
          if (res.data.data && res.data.data.id === prevCamEntryId) {
            setPrevCamEntry(res.data.data);
          } else {
            setErrorMessage({
              ...errorMessage,
              submit: "Invalid previous entry id.",
            });
          }
        })
        .catch((err) => {
          console.error("Error occurred:", err);
          setErrorMessage({
            ...errorMessage,
            submit: "An error occurred while processing data.",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setErrorMessage({
        ...errorMessage,
        submit: "Invalid previous entry id.",
      });
    }
  }, []);

  useEffect(() => {
    if (!prevCamEntry) return;

    if (prevCamEntry?.selectedRewardIds?.length) {
      setUserSelectedRewardId(prevCamEntry?.selectedRewardIds[0]);
    }

    if (prevCamEntry?.campaignInput?.receiptAmount) {
      setReceiptAmount(prevCamEntry?.campaignInput?.receiptAmount);
    }
  }, [prevCamEntry]);

  const onSubmit = async (e) => {
    setErrorMessage({
      ...errorMessage,
      submit: null,
    });

    if (!imageFile) {
      setErrorMessage({
        ...errorMessage,
        submit: "Please upload your receipt image!",
      });
      return;
    }

    const data = new FormData();
    data.append("image", imageFile);

    let _rewardId;

    // WATSON CAMPAIGN REWARD
    // Same goes to SG CAMPAIGN REWARD
    if (receiptAmount && campaign?.id === SG_CAMPAIGN_ID) {
      const rewardOption =
        campaign && campaign.id === SG_CAMPAIGN_ID
          ? CAPITAVOUCHER_REWARD_OPTIONS
          : null;

      const selectedReward = rewardOption?.find((option) => {
        if (option.minReciptAmount && option.maxReciptAmount) {
          return (
            receiptAmount >= option.minReciptAmount &&
            receiptAmount <= option.maxReciptAmount
          );
        } else if (option.minReciptAmount) {
          return receiptAmount >= option.minReciptAmount;
        }
      });

      if (selectedReward?.rewardId) {
        _rewardId = selectedReward?.rewardId;
      } else {
        setErrorMessage({
          ...errorMessage,
          submit: "Kindly enter a minimum amount of $38",
        });
        return;
      }
    }
    setIsLoading(true);

    let entryData = {
      campaignId: campaign.id,
      type: "RECEIPT",
      selectedRewardIds: _rewardId ? [_rewardId] : [],
      data: {
        ...(receiptAmount && {
          campaignInput: {
            receiptAmount: receiptAmount,
          },
        }),
        // for resubmit entry
        ...(prevCamEntry &&
          isResubmit && {
            prevCampaignEntryId: prevCamEntry.id,
          }),
      },
    };

    try {
      let isValidEntry = false;
      if (isResubmit) {
        // Check resubmit-entry validity, to prevent uploading image if entry is invalid
        const validityResponse = await axiosClient.post(
          `/haleon-cny/campaigns/entries/resubmit-validity`,
          {
            prevCampaignEntryId: prevCamEntry.id,
          }
        );

        if (validityResponse.data.isValid) {
          isValidEntry = true;
        }
      } else {
        // Check entry validity, to prevent uploading image if entry is invalid
        const validityResponse = await axiosClient.post(
          `/haleon-cny/campaigns/entries/validity`,
          entryData
        );

        if (validityResponse.data.isValid) {
          isValidEntry = true;
        }
      }

      if (!isValidEntry) {
        throw new Error("Invalid entry.");
      }

      // Upload image
      const uploadImageResponse = await axiosClient.post(
        `/campaigns/${campaign.id}/upload-image`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (
        uploadImageResponse.data.data.url &&
        uploadImageResponse.data.data.key
      ) {
        // Update entry data with image data
        entryData = {
          ...entryData,
          data: {
            ...entryData.data,
            imageData: {
              url: uploadImageResponse.data.data.url,
              key: uploadImageResponse.data.data.key,
            },
          },
        };

        // Create entry
        const entryResponse = await axiosClient.post(
          `/haleon-cny/campaigns/entries`,
          entryData
        );
        if (entryResponse.data.id) {
          // Set upload success
          setUploadSuccess(true);
        }
      } else {
        throw new Error("Error on uploading image.");
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage({
          ...errorMessage,
          submit: error.response.data.message,
        });
      } else {
        setErrorMessage({
          ...errorMessage,
          submit: "An error occurred while processing data.",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
        backgroundImage: `url(${primaryBgImg})`,
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",

        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? "none" : "auto",
      }}
    >
      {/* VALIDATE USER CHANNEL */}
      <UserChannelValidationModal
        redirectRoutePath="/upload"
        checkCampaignPeriod={true}
      />

      {/* UPLOAD SUCCESS MODAL */}
      <ReceiptUploadSuccessModal
        open={uploadSuccess}
        onClose={() => {
          setUploadSuccess(false);
          navigate("/home", {
            state: {
              promptType: "staytuned",
            },
          });
        }}
      />

      <div className="rewards-wrap">
        <div>
          <img className="header-text-img" src={textLogo} alt="" />
        </div>

        <p className="t-white font-weight-600 font-26">Upload Receipt</p>
        {imageUrl ? (
          <div className="mt-3">
            <div
              style={{
                margin: "0 10% 0 10%",
                overflow: "hidden",
                height: "20vh",
              }}
            >
              <img
                src={imageUrl}
                alt="receipt"
                onClick={() => uploadref.current.click()}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        ) : (
          <div
            className="upload-img-container"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              lineHeight: 1,
            }}
            onClick={() => uploadref.current.click()}
          >
            <img
              src={upload_img}
              alt="upload-img-icon"
              style={{ width: "20%" }}
            />
            <p>
              <span className="font-18 t-white">Upload receipt photo</span>
              <br />
              <span style={{ fontSize: "10px" }} className="t-white">
                *Supported image format: JPEG, JPG, PNG only
              </span>
            </p>
          </div>
        )}
        <p
          className="font-12 font-weight-400"
          style={{
            margin: "2% 10% 0 10%",
            textAlign: "left",
          }}
        >
          Notes: Your receipt will be validated after receipt submission in 3
          working days{" "}
        </p>
      </div>

      <div>
        <input
          type="file"
          name="receiptImage"
          accept="image/*"
          ref={uploadref}
          onChange={(e) => handleDisplayImage(e)}
          style={{ width: "0px", height: "0px", opacity: "0" }}
          required
        />
      </div>

      <div
        style={{
          margin: "0 10% 0 10%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <label
          style={{
            color: "#FFF",
            fontFamily: "Hero New",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            textAlign: "left",
          }}
        >
          Total Amount
        </label>
        <label
          style={{
            color: "#FFF",
            fontFamily: "Hero New",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
            textAlign: "left",
            marginBottom: "8px",
          }}
        >
          (Sum of participating products ONLY){" "}
        </label>
        <Input
          type="number"
          placeholder="Amount(SGD)"
          value={receiptAmount}
          style={{
            padding: "16px",
            fontSize: "14px",
            borderRadius: "4px",
            border: "1px solid var(--light-color-base-tertiary-dark, #DDD)",
            background: "#FFF",
            boxShadow: "0px 0px 7.6px 0px rgba(0, 0, 0, 0.25) inset",
          }}
          disabled={isResubmit}
          onChange={(e) => {
            let inputValue = e.target.value;
            const regexReceiptAmount = /^\d*\.?\d{0,2}$/;
            if (regexReceiptAmount.test(inputValue)) {
              setReceiptAmount(inputValue);
            }
          }}
        />
      </div>

      <div style={{ margin: "10% 10% 5% 10%" }}>
        {/* SHOW ERROR MESSAGE */}
        {Object.values(errorMessage).filter((_v) => _v).length > 0 && (
          <div
            style={{
              margin: "10px auto",
              background: "white",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <p
              style={{
                color: "red",
                fontSize: "12px",
                margin: "0",
                fontWeight: "bold",
              }}
            >
              {Object.values(errorMessage)?.map((err, index) => (
                <span key={index}>{err}</span>
              ))}
            </p>
          </div>
        )}

        <Button
          style={{
            width: "100%",
            textAlign: "center",
            borderRadius: "5px",
            background: "#63BC46",
            border: "none",
            color: "#FFF",
            fontWeight: "700",
            height: "40px",
          }}
          onClick={onSubmit}
        >
          Submit Receipt
        </Button>

        <Button
          style={{
            width: "100%",
            textAlign: "center",
            borderRadius: "5px",
            background: "#D4D4D4",
            border: "none",
            color: "white",
            fontWeight: "700",
            height: "40px",
            marginTop: "3%",
          }}
          onClick={() => navigate("/home")}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default Upload;
