
const LogoutBtn = () => {
  return (
    <div >
      <svg
        width="43"
        height="43"
        viewBox="0 0 43 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_301_11445)">
          <rect
            x="4.5"
            y="0.5"
            width="34"
            height="34"
            rx="5.5"
            fill="white"
            stroke="#D7D7D7"
          />
          <path
            d="M28.9786 13.3333L27.7116 14.5083L30.0299 16.6667H20.8914V18.3333H30.0299L27.7116 20.4833L28.9786 21.6667L33.4714 17.5L28.9786 13.3333ZM17.2971 11.6667H24.4857V10H17.2971C16.3087 10 15.5 10.75 15.5 11.6667V23.3333C15.5 24.25 16.3087 25 17.2971 25H24.4857V23.3333H17.2971V11.6667Z"
            fill="black"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_301_11445"
            x="0"
            y="0"
            width="43"
            height="43"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_301_11445"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_301_11445"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
export default LogoutBtn;
