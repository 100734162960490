import capitaVoucher from "../../images/capitaVoucher.png";
import React from "react";

const rewardImgSrc = {
  CAPITAVOUCHER: capitaVoucher,
};
const getRewardImg = (type) => rewardImgSrc[type];

const EnvelopHeaderRewardAmount = ({ reward, resizeByLength }) => {
  const RewardImg = ({ type, defaultSize }) => (
    <div
      style={{
        width: resizeByLength(defaultSize),
        padding: "0px 2px",
        position: "relative",
        bottom: "15px",
      }}
    >
      <img
        src={getRewardImg(type)}
        alt="Rewards Logo"
        style={{
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );

  const RewardAmountText = ({ defaultSize }) => (
    <p
      style={{
        margin: "0",
        color: "#285EAC",
        fontFamily: "Open Sans",
        fontSize: resizeByLength(defaultSize),
        fontStyle: "normal",
        fontWeight: 700,
        letterSpacing: "0.36px",
      }}
    >
      {reward.amount}
    </p>
  );

  const RewardDescriptionText = ({ defaultSize }) => (
    <p
      style={{
        margin: "-5px 0 0",
        color: "#40CED9",
        fontFamily: "Open Sans",
        fontSize: resizeByLength(defaultSize),
        fontStyle: "normal",
        fontWeight: 700,
        letterSpacing: "0.21px",
        textAlign: "center",
      }}
    >
      {reward.amount}
    </p>
  );

  if (reward?.types && reward?.types.length > 0) {
    return (
      <div
        style={{
          margin: "0 auto",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <RewardAmountText defaultSize={44} />
        <RewardDescriptionText defaultSize={12} />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          {React.Children.toArray(
            reward.types.map((type) => (
              <RewardImg type={type} defaultSize={40} />
            ))
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        margin: "0 auto",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <RewardDescriptionText defaultSize={50} />
      <RewardImg type={reward.type} defaultSize={220} />
    </div>
  );
};

export default EnvelopHeaderRewardAmount;
