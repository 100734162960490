export default function RewardIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
			<g clipPath="url(#clip0_301_10211)">
				<path
					d="M18 5.63371C18 5.01243 17.4963 4.50871 16.875 4.50871H14.9546C15.4516 4.01849 15.7697 3.37585 15.7697 2.5844C15.7697 1.60986 15.21 0.551239 13.6389 0.551239C11.5717 0.551239 9.84487 2.7523 9.03347 3.98615C8.2215 2.75258 6.44175 0.55152 4.37484 0.55152C2.80378 0.55152 2.24409 1.61015 2.24409 2.58468C2.24409 3.37583 2.57091 4.01877 3.078 4.50899H1.125C0.503719 4.50899 0 5.01271 0 5.63399V9.0014H1.13484V16.3237C1.13484 16.945 1.63854 17.4487 2.25984 17.4487H15.7542C16.3755 17.4487 16.8792 16.945 16.8792 16.3237V9.00111H18V5.63371H18ZM13.6389 1.67652C14.3345 1.67652 14.6447 1.96876 14.6447 2.58469C14.6447 3.83429 13.25 4.50843 11.9382 4.50843H10.0339C10.8332 3.32127 12.24 1.67652 13.6389 1.67652ZM4.37483 1.67654C5.77404 1.67654 7.234 3.32129 8.03331 4.50816H6.1284C4.81665 4.50816 3.36906 3.81657 3.36906 2.56698C3.36906 1.95132 3.67928 1.67654 4.37483 1.67654ZM16.875 7.87611H9.56248V5.63371H16.875V7.87611ZM1.12498 5.63371H8.43748V7.87611H1.12498V5.63371ZM2.25983 9.00111H8.43748V16.3235H2.25983V9.00111ZM15.7545 16.3235H9.56248V9.00111H15.7545V16.3235Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_301_10211">
					<rect width="18" height="18" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}