import { Modal, Button } from "antd";
import tnc_red from "../../images/tnc_red.png";

const TnC = ({ tnc, setTnc, type }) => {
  return (
    <div>
      {process.env.REACT_APP_REGION === "SG" && type === "OTHERS" && (
        <Modal open={tnc} centered footer={null} closable={false}>
          <div className="text-center">
            <img
              style={{ width: "50px", height: "65px" }}
              src={tnc_red}
              alt="tnc_icon"
            />
            <div className="mt-1" style={{ lineHeight: "40px" }}>
              <h3 className="mt-2 font-weight-700 font-20">
                Terms & Conditions
              </h3>
            </div>
            <ol
              style={{
                padding: "0 15px",
                textAlign: "justify",
                textJustify: "auto",
              }}
            >
              <li className="my-1 font-weight-400 font-14">
                {" "}
                A person who is not a CapitaStar member and/or who does not have
                or use the CapitaStar App may accept a gift of Digital
                CapitaVoucher through the Web Wallet Website. Such person must
                first:
                <br />
                (a) have agreed to these Terms and the Web Wallet Website T&Cs
                in Annex A of these Terms;
                <br />
                (b) received a valid link to the Web Wallet Website to accept
                the gift of Digital CapitaVouchers; and
                <br />
                (c) verified their mobile number and/or email address via the
                Web Wallet Website.
                <br />
              </li>
              <li className="my-1">
                This Digital CapitaVoucher will be expired on 31 July 2024.
              </li>
              <li className="my-1">
                Any information submitted to CVPL through the Web Wallet Website
                to accept and/or use Digital CapitaVoucher through the Web
                Wallet Website shall be in such form and contain such
                information, and shall be made in accordance with such
                procedures, as CVPL may from time to time specify. Once any such
                information has been submitted, it cannot be cancelled, modified
                or withdrawn.
              </li>
              <li className="my-1">
                CVPL shall not be responsible for any fraudulent, irregular or
                unsuccessful acceptance of Digital CapitaVoucher through the Web
                Wallet Website. Without prejudice to paragraph 34, CVPL may
                refuse to credit any Digital CapitaVoucher to a person’s Digital
                CapitaVoucher Wallet if CVPL determines, in its sole discretion,
                that the gift is not or should not have been successful, or is
                or was fraudulent or irregular, including but not limited to
                where the aggregate value in paragraph 21 will be exceeded. For
                the avoidance of doubt, any Digital CapitaVoucher which has been
                accepted into a person’s Digital CapitaVoucher Wallet may not be
                gifted to any other person.
              </li>
              <li className="my-1">
                A gift of Digital CapitaVoucher(s) may only be accepted into the
                Giftee’s Digital CapitaVoucher Wallet (a) if the Giftee
                satisfies the conditions in paragraph 14; and (b) subject to the
                conditions in paragraph 21. Any dispute arising from or relating
                to the Digital CapitaVoucher accepted or to be accepted by the
                Giftee via the Web Wallet Website shall be settled between the
                Giftee and the Gifter of the Digital CapitaVoucher.
              </li>
              <li className="my-1">
                A Digital CapitaVoucher, once issued, cannot be returned,
                exchanged or replaced for any CapitaVoucher(s) or STAR$ of any
                value. CVPL is not obliged to provide any refund, exchange,
                replacement or transfer of Digital CapitaVoucher.
              </li>
            </ol>
            <Button
              type="primary"
              className="mt-1"
              onClick={() => setTnc(false)}
              style={{
                fontSize: "18px",
                borderRadius: "8px",
                background: "#CB0E07",
                color: "white",
                border: "none",
                fontWeight: "500",
                height: "40px",
                width: "90%",
              }}
            >
              Close
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TnC;
