import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import capitaVoucher from "../images/capitaVoucher.png";
import { useNavigate, useLocation } from "react-router-dom";
import copy from "../images/copy.png";
import primaryBgImg from "../images/testing.png";
import textLogo from "../images/textLogo.png";
import HowToRedeem from "../components/Modal/HowToRedeem";
import TnC from "../components/Modal/TnC";
import axiosClient from "../utils/axiosClient";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserEligibleCNYCampaignByChannelIfNotExist } from "../redux/campaign/campaignActions";
import CopyToClipboard from "../components/Modal/CopyToClipboard";
import { fetchUserProfileIfNotExist } from "../redux/user/userActions";
import { CopyToClipboard as CopyToClipboardLib } from "react-copy-to-clipboard";

const Rewards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [redeem, setRedeem] = useState(false);
  const [tnc, setTnc] = useState(false);
  const [redeemType, setRedeemType] = useState();
  const [tncType, setTncType] = useState();
  const [selectedReceipt, setSelectedReceipt] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const currentPage = location.pathname;
  const {
    userProfile,
    isLoading: userRLoading,
    error: userRError,
  } = useSelector((state) => state.userReducer);
  const {
    campaign,
    isLoading: camRLoading,
    error: camRError,
  } = useSelector((state) => state.campaignReducer);

  useEffect(() => {
    const _error = userRError || camRError;
    // must be string
    if (_error && typeof _error === "string") {
      message.error(_error);
    }
  }, [userRError, camRError]);

  // FETCH USER ELIBIGLE CAMPAIGN DETAILS
  useEffect(() => {
    if (userRError || camRError) {
      return;
    }

    if (!userRLoading && userProfile && !camRLoading && !campaign) {
      const userChannel = userProfile?.personalInfo?.registerSource?.channel;
      if (!userChannel) {
        console.error("user missing registerSource channel ");
        return;
      }
      dispatch(fetchUserEligibleCNYCampaignByChannelIfNotExist(userChannel));
    }
  }, [
    userProfile,
    userRLoading,
    camRLoading,
    campaign,
    dispatch,
    userRError,
    camRError,
  ]);

  useEffect(() => {
    dispatch(fetchUserProfileIfNotExist());
  }, []);

  const handleHowToRedeem = (type) => {
    setRedeem(true);
    setRedeemType(type);
  };

  const handleTermsCondition = (type) => {
    setTnc(true);
    setTncType(type);
  };

  useEffect(() => {
    // if (campaign && campaign.id) {
    const getReceiptRewards = async () => {
      try {
        const res = await axiosClient.get("/order/get");
        if (res && res.data.checkVoucher) {
          // console.info("res", res.data.checkVoucher);
          setRewards(res.data.checkVoucher);
        }
      } catch (e) {
        console.error("error", e);
      }
    };

    getReceiptRewards();
  }, [campaign, campaign?.id]);

  const voucherClick = async (ele) => {
    setSelectedReceipt(ele);
    try {
      const res = await axiosClient.post("/voucher/check", {
        id: ele.rewardInstanceId,
        value: ele.rewardInstance.value,
      });
      if (res) {
        navigator.clipboard.writeText(ele.rewardInstance.value);
        setIsClick(true);
        setTimeout(() => {
          setIsClick(false);
        }, 1000);
      } else {
        console.info("error", res.message);
      }
    } catch (e) {
      console.error("error", e);
    }
  };

  return (
    <div
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
        backgroundImage: `url(${primaryBgImg})`,
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <div className="rewards-wrap">
        <div>
          <img className="header-text-img" src={textLogo} alt="" />
        </div>
        <div className="primary-buttons">
          <Button
            onClick={() => navigate("/rewards")}
            style={{
              backgroundColor:
                currentPage === "/rewards" ? "#63bc46" : "#BDBDBD",
            }}
            className="myButton"
          >
            My Rewards
          </Button>
          <Button
            onClick={() => navigate("/submissions")}
            style={{
              backgroundColor:
                currentPage === "/submissions" ? "#63bc46" : "#BDBDBD",
            }}
            className="myButton"
          >
            My Submissions
          </Button>
        </div>

        <div className="rewards-list">
          {rewards && rewards.length ? (
            <div>
              {rewards.map((item, index) => {
                return (
                  <div
                    className="rewards-container"
                    style={{ margin: "3% auto" }}
                    key={index}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          marginLeft: "5%",
                          paddingTop: "3%",
                        }}
                      >
                        <img
                          src={
                            item.rewardInstance.reward.voucherType ===
                              "OTHERS" &&
                            (item.rewardInstance.reward.shortCode ===
                              "CNYNTCV2" ||
                              item.rewardInstance.reward.shortCode ===
                                "CNYNTCV")
                              ? capitaVoucher
                              : null
                          }
                          alt="voucher_img"
                          style={{
                            width: "70px",
                            height: "70px",
                            objectFit: "contain",
                            fontSize: "15px",
                          }}
                        />
                        <div>
                          <h1 className="text-left font-16 font-weight-700">
                            {item.rewardInstance.reward.name}
                          </h1>
                          <p className="text-left font-10 font-weight-400">
                            {item.rewardInstance.reward.shortCode === "CNYNTCV2"
                              ? item.rewardInstance.reward.description
                              : "No description found"}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "7%",
                          marginRight: "7%",
                        }}
                      >
                        <div>
                          <p
                            className="font-12 font-weight-400"
                            style={{
                              position: "relative",
                              top: "1.25rem",
                            }}
                          >
                            exp. March 31
                          </p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            width: "50%",
                            height: "40px",
                            borderRadius: "5px",
                            background: "#C4113C",
                            color: "white",
                            border: "none",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "3%",
                          }}
                          className="font-16 font-weight-600"
                          onClick={() => voucherClick(item)}
                        >
                          <CopyToClipboardLib text={item.rewardInstance.value}>
                            <p
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                // display: "flex",
                                // alignItems: "center",
                                whiteSpace: "nowrap",
                                fontSize: "9px",
                              }}
                            >
                              {item.rewardInstance.value}
                            </p>
                          </CopyToClipboardLib>
                          <img
                            src={copy}
                            alt="copy_icon"
                            style={{
                              objectFit: "contain",
                              textAlign: "center",
                              verticalAlign: "middle",
                              width: "21px",
                              height: "24px",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "7%",
                          marginRight: "7%",
                        }}
                      >
                        <p
                          onClick={() =>
                            handleHowToRedeem(
                              item.rewardInstance.reward.voucherType
                            )
                          }
                          style={{
                            color: " #0D5FA8",
                            textDecoration: " underline",
                          }}
                          className="font-12 font-weight-400"
                        >
                          How To Redeem
                        </p>

                        <p
                          onClick={() =>
                            handleTermsCondition(
                              item.rewardInstance.reward.voucherType
                            )
                          }
                          style={{
                            color: "#929292",
                            textAlign: "right",
                            textDecoration: " underline",
                          }}
                          className="font-12 font-weight-400"
                        >
                          Terms & Conditions
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            "No Rewards Yet"
          )}
        </div>
        <div style={{ margin: "5% 10% 2% 10%" }}>
          <Button
            style={{
              width: "100%",
              textAlign: "center",
              borderRadius: "5px",
              background: "#63BC46",
              border: "none",
              color: "#FFF",
              fontWeight: "700",
              height: "40px",
            }}
            onClick={() => navigate("/home")}
          >
            Back
          </Button>
        </div>

        <HowToRedeem redeem={redeem} setRedeem={setRedeem} type={redeemType} />
        <TnC tnc={tnc} setTnc={setTnc} type={tncType} />
        <CopyToClipboard isClick={isClick} setIsClick={setIsClick} />
      </div>
    </div>
  );
};

export default Rewards;
