import axiosClient from "../../utils/axiosClient";
import { SET_CAMPAIGN, SET_CAM_ERROR, SET_CAM_LOADING } from "./campaignActionTypes";

export const fetchUserEligibleCNYCampaignByChannelIfNotExist = (channel) => async (dispatch, getState) => {
	try {
		const { campaign } = getState()?.campaignReducer;

		const isExistingCampaignChannel = campaign?.config?.channels?.includes(channel);

		if (!campaign || !isExistingCampaignChannel) {
			dispatch({ type: SET_CAM_ERROR, payload: null });
			dispatch({ type: SET_CAM_LOADING, payload: true });

			const res = await axiosClient.get(`/haleon-cny/campaigns/${channel}/validity`);
			dispatch({ type: SET_CAMPAIGN, payload: res.data });
		}
	} catch (error) {
		const errorMessage = error?.response?.data?.message || "Unable fetch profile.";

		dispatch({ type: SET_CAM_ERROR, payload: errorMessage });
	}
};
