import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthRoute = ({ children }) => {
	const navigate = useNavigate();


	const userToken = localStorage.getItem("user-token");


	useEffect(() => {
		if (!userToken) {
			navigate(`/login${window.location.search}`);
		}
	}, [navigate, userToken]);


	return children;
};

export default AuthRoute;
