import React from "react";

const BrandList = ({ brands }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
  
        gap: "10px",
  
        width: "100%",
      }}
    >
      {React.Children.toArray(
        brands.map(({ src, alt }) => {
          const brandImgStyle = {
            width: "80%",
            height: "80%",
          };
  
          return (
            <div>
              <img src={src} alt={alt} style={brandImgStyle} />
            </div>
          );
        })
      )}
    </div>
  );
  
export default BrandList;  