import { SET_CAMPAIGN, SET_CAM_ERROR, SET_CAM_LOADING } from './campaignActionTypes';

const initialState = {
	campaign: null,
	isLoading: false,
	error: null,
};

const campaignReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CAM_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case SET_CAMPAIGN:
			return {
				...state,
				isLoading: false,
				campaign: action.payload,
			};
		case SET_CAM_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

export default campaignReducer;
