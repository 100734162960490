import headingTitleImg from "./assets/heading-title.png";

export default function HeadingTitle() {
	return (
		<div
			style={{
				marginTop: "10%",
				paddingLeft:"50px",
				width: "85%",
			}}
		>
			<div
				style={{
					width: "80%",
					minWidth: "50px",
					maxWidth: "340px",
				}}
			>
				<img
					alt="Start A healthy New year"
					src={headingTitleImg}
					style={{
						minWidth: "180px",

						width: "100%",
						height: "auto",
					}}
				/>
			</div>
		</div>
	);
}
