import { useEffect, useState } from "react";
import invoiceIcon from "../../images/invoiceIcon.png";
import PButton from "../PButton/PButton";
import UploadIcon from "../icons/UploadIcon";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../utils/axiosClient";
import { useSelector } from "react-redux";

export default function UserUserUploadReceiptBanner({onClick}) {
  const navigate = useNavigate();
  // const [userInfo, setUserInfo] = useState([]);
  const [error, setError] = useState();

  // useEffect(() => {
  //   const checkUser = async () => {
  //     try {
  //       const res = await axiosClient.get('/users/details');
  //       if (res && res?.data) {
  //         setUserInfo(res.data.data.personalInfo.name);
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //       setError(error.response?.data?.error);
  //       navigate("/login");
  //     }
  //   };
  //   checkUser();
  // }, [navigate]);
  
  const { userProfile } = useSelector((state) => state.userReducer);
  const userName = userProfile?.personalInfo?.name || "";

  return (
    <div
      style={{
        width: "80%",

        margin: "0px 30px",
        padding: "4%",

        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        flexWrap: "wrap-reverse",
        alignItems: "center",

        minHeight: "100px",

        borderRadius: "5px",
        background: "#FBFAFC",
        boxShadow: "5px 5px 20px 0px rgba(60, 60, 67, 0.15)",
      }}
    >
      <div
        style={{
          flexBasis: "60%",
          flex: 2,

          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",

          textAlign: "left",
        }}
      >
        <h3
          style={{
            color: "#000",
  fontFamily: "Hero New",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 600,
            lineHeight: "normal",
  margin:"inherit"
          }}
        >
          Hello, {userName}
        </h3>

        <p
          style={{
            color: "var(--Main-Midnight-Black, #262626)",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "20px"
          }}
        >
          Upload purchase receipt to
          <br /> receive rewards!
        </p>

        <PButton
          style={{
            width: "fit-content",
            height: "fit-content",

            alignSelf: "flex-start",

            color: "var(--Main-Off-White, #FBFAFC)",
  fontFamily: "Hero New",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal"
          }}
          onClick={onClick}
        >
          <UploadIcon />
          Upload Receipt
        </PButton>
      </div>

      {/* INVOICE ICON */}
      <div
        style={{
          height: "100%",

          flexBasis: "30%",
          flex: 1,

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="Upload Receipt Icon"
          src={invoiceIcon}
          style={{
            maxWidth: "80px",

            width: "100%",
            // height: "100%",
          }}
        />
      </div>
    </div>
  );
}
