import { Button } from "antd";
import goldButton from "./assets/goldButton.svg";

import calsourceBrand from "./assets/brands/calsource.png";
import caltrateBrand from "./assets/brands/caltrate.png";
import centrumBrand from "./assets/brands/centrum.png";
import enoBrand from "./assets/brands/eno.png";
import panaflexBrand from "./assets/brands/panaflex.png";
import parodontaxBrand from "./assets/brands/parodontax.png";
import polidentBrand from "./assets/brands/polident.png";
import scottsBrand from "./assets/brands/scotts.png";
import sensodyneBrand from "./assets/brands/sensodyne.png";
import angPao from "./assets/ang-pao.png";
import angPaoBg from "./assets/ang-pao_bg.png";
import footerBrandSvg from "./assets/footerBrandBg.svg";
import React from "react";
import HelpDeskHover from "../HelpDeskHover/HelpDeskHover";
import { useWindowSize } from "@uidotdev/usehooks";
import BrandList from "./BrandList";
import { resizeByPercentageOfLengthInPx } from "../../utils/helper";
import EnvelopHeaderRewardAmount from "./EnvelopHeaderRewardAmount";

import { HALEON_CNY_CAMPAIGNS } from "../../consts/haleonCnyCampaignsChannels";

const BRANDS_IMAGES_ROW_1 = [
  { name:'panaflex', src: panaflexBrand, alt: "panaflex Brand logo" },
  { name:'sensodyn', src: sensodyneBrand, alt: "sensodyne Brand logo" },
  { name:'polident', src: polidentBrand, alt: "polident Brand logo" },
  { name:'scotts', src: scottsBrand, alt: "scott's Brand logo" },
].filter((brand) => {
  const isSG = process.env.REACT_APP_REGION === "SG"; 
  const filterBrandsForSG = ["panaflex"];
  if (isSG && filterBrandsForSG.includes(brand.name.toLowerCase())) {
    return false;
  }
  return true;
});

const BRANDS_IMAGES_ROW_2 = [
  { name:'centrum', src: centrumBrand, alt: "centrum Brand logo" },
  { name:'caltrate', src: caltrateBrand, alt: "caltrate Brand logo" },
  { name:'parodontax', src: parodontaxBrand, alt: "parodontax Brand logo" },
  { name:'calsource', src: calsourceBrand, alt: "calsource Brand logo" },
  { name:'eno', src: enoBrand, alt: "eno Brand logo" },
].filter((brand) => {
  const isSG = process.env.REACT_APP_REGION === "SG"; 
  const filterBrandsForSG = ["calsource"];
  if (isSG && filterBrandsForSG.includes(brand.name.toLowerCase())) {
    return false;
  }
  return true;
});

const sgEWallets = [
  {
    reward: {
      type: "CAPITAVOUCHER",
      amount: "$3",
      description: null,
    },
    periodComponent: (
      <>
        1<sup>st</sup>January - 29<sup>th</sup> February 2024
      </>
    ),
    minPurchase: "$38",
  },
  {
    reward: {
      type: "CAPITAVOUCHER",
      amount: "$8",
      description: null,
    },
    periodComponent: (
      <>
        1<sup>st</sup>January - 29<sup>th</sup> February 2024
      </>
    ),
    minPurchase: "$68",
  },
];

export default function EWalletCreditRedeem({ campaign, onClickRedeemNow }) {
  const windowWidth = useWindowSize().width;

  const eWalletsBasedOnCampaignId = {
    [HALEON_CNY_CAMPAIGNS.SG]: sgEWallets,
  };

  const ewalletCampaign = eWalletsBasedOnCampaignId?.[campaign?.id] || [];

  const length = windowWidth <= 350 ? 2 : ewalletCampaign.length;
  const resizeByLength = (defaultSize) =>
    resizeByPercentageOfLengthInPx(defaultSize, length, 30);

  const resizeByLengthWalletHeader = (defaultSize) =>
    resizeByPercentageOfLengthInPx(defaultSize, length, 32);

  return (
    <div
      style={{
        width: "100%",
        minWidth: "280px",
        height: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",

        overflow: "hidden",
      }}
    >
      <div
        style={{
          backgroundwidth: "100%",
          backgroundImage: `url(${footerBrandSvg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundSize: "cover",
          alignContent: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "column",
          minHeight: "260px",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${angPaoBg})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom center",
            backgroundSize: "100% 100%",
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
          }}
        >
          {/* HELP DESK HOVER */}
          <HelpDeskHover />

          {/* ENVELOPS */}
          <div
            style={{
              width: "90%",
              height: "auto",
              display: "flex",
              alignItems: "center",
              flexDirection: windowWidth < 350 ? "column" : "row",
            }}
          >
            {React.Children.toArray(
              ewalletCampaign.map((ewallet) => (
                <div
                  style={{
                    flex: 1,
                    margin: "0 -10%",

                    position: "relative",
                    width: "100%",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "360px",
                      width: "100%",
                    }}
                    src={angPao}
                    alt="E-Wallet Envelop"
                  />

                  {/* CONTENT */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",

                      width: "70%",
                      height: "85%",

                      position: "absolute",
                      zIndex: 100,
                    }}
                  >
                    {/* REWARD AMOUNT */}
                    <EnvelopHeaderRewardAmount
                      reward={ewallet.reward}
                      resizeByLength={resizeByLengthWalletHeader}
                    />

                    {/* DETAILS */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "#FBFAFC",
                          fontFamily: "Hero New",
                          fontSize: resizeByLength(14),
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",

                          margin: "0",
                        }}
                      >
                        Campaign Period
                        <br />
                        {ewallet.periodComponent}
                      </p>

                      <h4
                        style={{
                          color: "#FBFAFC",
                          fontFamily: "Hero New",
                          fontSize: resizeByLength(18),
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "normal",

                          margin: "2%",
                        }}
                      >
                        Upon Purchase of {ewallet.minPurchase}
                      </h4>

                      <Button
                        onClick={onClickRedeemNow}
                        style={{
                          backgroundImage: `url(${goldButton})`,
                          backgroundOrigin: "center",
                          backgroundSize: "100% 100%",
                          backgroundRepeat: "no-repeat",
                          backgroundColor: "transparent",
                          border: "none",

                          height: "100%",
                          width: "fit-content",

                          fontSize: resizeByLength(18),
                          letterSpacing: "0.02em",
                          fontWeight: "800",
                          color: "#fe5640",
                          padding: "12px 8px",
                          pointerEvents: "all",
                        }}
                      >
                        Redeem Now
                      </Button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Brands */}
        <div
          style={{
            width: "80%",
            margin: "0 auto",
          }}
        >
          <BrandList brands={BRANDS_IMAGES_ROW_1} />
          <BrandList brands={BRANDS_IMAGES_ROW_2} />
        </div>
      </div>
    </div>
  );
}
