import React, { useState } from "react";
import { Modal, Checkbox, Button } from "antd";
import axiosClient from "../../utils/axiosClient";
import panaflex from "../../images/questionnaire/panaflex.svg";
import sensodyne from "../../images/questionnaire/sensodyne.svg";
import polident from "../../images/questionnaire/polident.svg";
import scotts from "../../images/questionnaire/scotts.svg";
import centrum from "../../images/questionnaire/centrum.svg";
import caltrate from "../../images/questionnaire/caltrate.svg";
import paradontax from "../../images/questionnaire/paradontax.svg";
import calsource from "../../images/questionnaire/calsource.svg";
import eno from "../../images/questionnaire/eno.svg";

const Questionnaire = ({ displayQues, setDisplayQues, channel }) => {
	const options = ["Yes", "No"];
	const [selectedOption, setSelectedOption] = useState(null);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [success, setSuccess] = useState();
	const [error, setError] = useState();

	const products = [
		{ img: panaflex, name: "Panaflex", width: "50px", height: "35px" },
		{ img: sensodyne, name: "Sensodyne", width: "80px", height: "25px" },
		{ img: polident, name: "Polident", width: "70px", height: "40px" },
		{ img: scotts, name: "Scott's", width: "42px", height: "35px" },
		{ img: centrum, name: "Centrum", width: "63px", height: "35px" },
		{ img: caltrate, name: "Caltrate", width: "50px", height: "40px" },
		{ img: paradontax, name: "Paradontax", width: "70px", height: "38px" },
		{ img: calsource, name: "Calsource", width: "50px", height: "43px" },
		{ img: eno, name: "Eno", width: "50px", height: "35px" },
	];

	const handleCheckboxChange = (value) => {
		setSelectedOption(value);
		setSelectedProducts([]);
	};

	const handleProductSelect = (selectedProduct) => {
		const isSelected = selectedProducts.includes(selectedProduct);

		if (isSelected) {
			const updatedProducts = selectedProducts.filter((product) => product !== selectedProduct);
			setSelectedProducts(updatedProducts);
		} else {
			setSelectedProducts([...selectedProducts, selectedProduct]);
		}
	};

	const handleSubmit = async () => {
		if (selectedOption) {
			const remark = {
				Question: "Have you purchased any Haleon products in the past 6 months?",
				Answer: { selectedOption },
				"Product(s)": selectedOption === "Yes" ? selectedProducts : "none",
			};

			try {
				if (selectedOption === "Yes" && !selectedProducts.length) {
					setError("Please select at least one product!");
					setTimeout(() => {
						setError(null);
					}, 1000);
					return;
				}

				const res = await axiosClient.post("/campaign/questionnaire", {
					remark: remark,
					campaignId: channel,
				});

				if (res) {
					setSuccess(res.data.message);
					setTimeout(() => {
						setDisplayQues(false);
					}, 1000);
				}
			} catch (err) {
				setError(err.response.data.message);
				console.error("error", err);
			}
		} else {
			setError("Please select an option first!");
			setTimeout(() => {
				setError(null);
			}, 1000);
		}
	};

	return (
		<div>
			<Modal open={displayQues} centered footer={null} closable={true} maskClosable={false} onCancel={() => setDisplayQues(false)} width={350}>
				<p className="font-weight-600 font-14 text-center mt-3">Have you purchased any Haleon products in the past 6 months?</p>
				<div className="text-center">
					{options.map((option) => (
						<div key={option} style={{ paddingTop: "3%" }}>
							<div
								style={{
									justifyContent: "space-between",
									display: "flex",
									alignItems: "center",
									padding: "3% 5% 3% 5%",
									backgroundColor: option === "Yes" && selectedOption === "Yes" ? "#FFEBAA" : "#F6F6F6",
								}}
							>
								{option} <Checkbox onChange={() => handleCheckboxChange(option)} checked={option === selectedOption} className="options-checkbox" />
							</div>
							<div>
								{option === "Yes" && selectedOption === "Yes" ? (
									<div>
										<div mode="multiple" placeholder="Select products" style={{ width: "100%" }}>
											{products.map((product, index) => (
												<div
													key={index}
													value={product}
													style={{
														justifyContent: "space-between",
														alignItems: "center",
														display: "flex",
														marginTop: "0.3rem",
														height: "42px",
														background: "#F6F6F6",
													}}
												>
													<img
														src={product.img}
														alt={product.name}
														style={{
															width: product.width,
															height: product.height,
															objectFit: "contain",
															padding: "5% 16px",
														}}
													/>
													<Checkbox onChange={() => handleProductSelect(product.name)} checked={selectedProducts.includes(product.name)} className={selectedProducts && "products-checkbox"} style={{ paddingRight: "7%" }} />
												</div>
											))}
										</div>
									</div>
								) : null}
							</div>
						</div>
					))}
				</div>

				<div
					style={{
						color: error ? "red" : "green",
						textAlign: "center",
						fontSize: "12px",
					}}
				>
					{error ? error : success}
				</div>

				<div>
					<p
						style={{
							textAlign: "right",

							color: "#838383",
							fontFamily: "Inter",
							fontSize: "14px",
							fontStyle: "normal",
							fontWeight: 400,
							lineHeight: "normal",
						}}
					>
						1/1
					</p>
				</div>

				{selectedOption && (
					<Button
						style={{
							width: "100%",
							textAlign: "center",
							borderRadius: "5px",
							background: "#63BC46",
							border: "none",
							color: "#FFF",
							fontWeight: "700",
							height: "40px",
							marginTop: "5%",
						}}
						onClick={handleSubmit}
					>
						Submit
					</Button>
				)}
			</Modal>
		</div>
	);
};

export default Questionnaire;
