import { Button, Modal } from "antd";
import checkImage from "../../images/checkmark.png";

export default function ReceiptUploadSuccessModal({ open, onClose }) {
  return (
    <Modal
      open={open}
      centered
      header={null}
      footer={null}
      onCancel={onClose}
      closable={false}
      width={300}
    >
      <div className="text-center">
        <img
          style={{ width: "20%" }}
          src={checkImage}
          alt=""
          className="submit-success-icon"
        ></img>
        <div style={{ fontWeight: "700", fontSize: "20px" }}>Success!</div>
        <p
          className="text-center mt-1 font-14 font-weight-400"
          style={{ lineHeight: "15px" }}
        >
          Your receipt has been successfully uploaded and will be validated
          within 3 working days.
        </p>
        <Button
          type="primary"
          className="mt-2 text-deco-none font-18 font-weight-500"
          style={{
            color: "white",
            width: "90%",
            background: "#02BC7D",
            fontWeight: "500",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
          onClick={onClose}
        >
          Done
        </Button>
      </div>
    </Modal>
  );
}