import React from 'react';
import {Modal, Button} from "antd";
import success from "../../images/success_icon.png";
import { useNavigate } from 'react-router-dom';

const Success = ({isModalOpen, setIsModalOpen,title, msg,location}) => {
    const navigate = useNavigate();
    return (
        <Modal
        open={isModalOpen}
        footer={null}
        closable={false}
        centered
        width={330}
    >
        <div className="text-center" >
                <img src={success} alt="" style={{
                    width: '22%',
                    marginBottom: '-8px'
                }} />
            <h2 style={{ fontWeight: '700', fontSize:'20px' }}>{title}</h2>
            <p className="text-center mt-1 font-14 font-weight-400" style={{lineHeight:'15px'}}>{msg}</p>
            <Button
                type="primary"
                className="mt-2 text-deco-none font-18 font-weight-500"
                style={{
                    color: 'white',
                    width: '90%',
                    background: '#02BC7D',
                    fontWeight: '500',
                    borderRadius: '5px',
                    display:'flex',
                    justifyContent:'center',
                    alignItems: 'center',
                    margin:'auto'
                }}
                // onClick={() => checkRegister()}
                onClick = {()=> navigate(location)}
            >
                DONE
            </Button>
        </div>
    </Modal>
    )
}
export default Success;